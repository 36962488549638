export const PROPERTY_TYPE_OPTIONS = [
  { value: 'apartment', label: 'Eigentumswohnung' },
  { value: 'single_family_house', label: 'Einfamilienhaus' },
  { value: 'semi_detached_house', label: 'Doppelhaushälfte' },
  { value: 'two_family_house', label: 'Zweifamilienhaus' },
  { value: 'property', label: 'Grundstück' },
  { value: 'corner_terraced_house', label: 'Reiheneckhaus' },
  { value: 'mid_terraced_house', label: 'Reihenmittelhaus' },
  { value: 'apartment_house', label: 'Mehrfamilienhaus' },
  {
    value: 'commercial_and_residential_building',
    label: 'Wohn- und Geschäftshaus',
  },
]

export const SALUTATION_OPTIONS = [
  { value: 'ms', label: 'Frau' },
  { value: 'mr', label: 'Herr' },
]
